import { Box, Grid, Typography } from "@mui/material";
import landing from "../images/landing.png";
import React, { useEffect, useState } from "react";
import { usePaulContext } from "../domain/PaulContext";

interface IProps {
  defaultStyle: {
    backgroundAttachment: string;
    backgroundSize: string;
    backgroundRepeat: string;
    backgroundPositionX: string;
    backgroundPositionY: number;
    zIndex: number;
  },
  height: number;
}

export const HelloThere: React.FC<IProps> = (props ) => {

  const { isMobile } = usePaulContext();
  const [ bgColorField, setBgColorField ] = useState(255);

  useEffect(() => {
    const scrollHandler = () => {
      setBgColorField(255 - (window.scrollY/props.height * 255))
    }
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return <Box
    sx={{
      ...props.defaultStyle,
      height: props.height,
      backgroundImage: `url(${landing})`,
      backgroundColor: `rgb(${bgColorField},${bgColorField},${bgColorField})`,
      display: "flex",
      flexDirection: "column"
    }}
  >
    <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
      <Grid container spacing={2} justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <Grid item xs={10} sm={9} lg={6}>
          <img src="https://paulpatena.com/public_images/paul_logo.png"
               alt="paul_logo.png"
               style={{width: '100%', height: '100%', objectFit: 'contain'}}
               className={'fade-in-image'}
          />
        </Grid>
      </Grid>

    </Box>
    <Box sx={{
      padding: 5,
      color: 'black',
      // color: `rgb(${255 - bgColorField},${255 - bgColorField},${255 - bgColorField})`,
    }}
    >
      <Typography
        className={"fade-right"}
        variant={isMobile ? "h3" : "h2"}
        component="div"
      >
        Hello there.
      </Typography>


      <Typography
        className={"fade-left"}
        variant={isMobile ? "h6" : "h4"}
      >
        &#128075; My name is Paul and I love architecting solutions and developing software.
      </Typography>
    </Box>
  </Box>;
}
