import { Avatar, Box, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { usePaulContext } from "../domain/PaulContext";
import { NavLinks } from "./NavLinks";
import { primary } from "../domain/MyTheme";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import AwsCSAA from "../images/aws-saa.png";
import AwsCDA from "../images/aws-da.png";
import ResumeIcon from "../images/resumeIcon.png";
import './Header.css';

export const Header: React.FC = () => {

  const { isMobile } = usePaulContext();
  const navBarHeight = useMemo(() => isMobile ? 48 : 60, [ isMobile ])
  const [ isRotating, setIsRotating ] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: navBarHeight,
          backgroundColor: primary,
        }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 4,
          flexBasis: 0,
          justifyContent: isMobile ? 'center' : 'flex-start',
        }}>

          <Typography
            variant="h6"
            component="div"
            sx={{ px: 2, pt: 0.5, cursor: 'pointer' }}
            className={isRotating ? 'image_rotate' : ''}
            onClick={()=>setIsRotating((prevState)=>!prevState)}
            title={'click to start/stop animation'}
          >
            Paul Patena
          </Typography>
          <Box>
            <IconButton title="LinkedIn account"
                        href="https://www.linkedin.com/in/paulpatena/" target="_blank">
              <LinkedInIcon></LinkedInIcon>
            </IconButton>
            <IconButton title="Github account"
                        href="https://github.com/PaulPatena" target="_blank">
              <GitHubIcon></GitHubIcon>
            </IconButton>
            <IconButton title="Resume"
                        href="https://paulpatena.com/public_images/Resume_PaulPatena_latest.pdf" target="_blank">
              <Avatar src={ResumeIcon} sx={{width: 24, height: 24, filter: 'hue-rotate(180deg)'}}></Avatar>
            </IconButton>
            <IconButton title="AWS Certified Solutions Architect Associate"
                        href="https://www.credly.com/badges/0c10a8e5-d58d-4c8b-93b5-fd0e9203f429" target="_blank">
              <Avatar src={AwsCSAA} sx={{width: 24, height: 24, filter: 'grayscale(100%)'}}></Avatar>
            </IconButton>
            <IconButton title="AWS Certified Developer Associate"
                        href="https://www.credly.com/badges/a83a06e7-a2d1-44ca-864e-e06a77b7eb04" target="_blank">
              <Avatar src={AwsCDA} sx={{width: 24, height: 24, filter: 'grayscale(100%)'}}></Avatar>
            </IconButton>


          </Box>
        </Box>
        {!isMobile &&
          <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
            <NavLinks/>
          </Box>
        }
      </Box>
      {isMobile &&
        <Box sx={{ flexGrow: 1, height: navBarHeight, backgroundColor: primary }}>
          <NavLinks/>
        </Box>
      }
    </>
  );
}
