import React from "react";
import { Box, Typography } from "@mui/material";
import { FadeInAnimator } from "../animators/FadeInAnimator";

interface IProps {
  bgColor: string;
  counter?: string;
  title: string;
  subtitle: string;
  isMobile: boolean;
}

export const DualBox: React.FC<IProps> = (props) => {
  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "row" }}>
      <Box sx={{
        flexGrow: 1,
        flexBasis: 0,
        backgroundColor: props.bgColor,
        opacity: 0.8,
        transition: "opacity 400ms ease-in",
        display: "flex",
        // ":hover": {
        //   opacity: 0.95,
        //   transition: "opacity 400ms ease-out", // slowly change the opacity
        // },
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
      }}
      >
        <FadeInAnimator>
          <Box sx={{ paddingX: 5 }}>
            <Typography variant="h3">
              {props.counter}{props.counter && <br/>}
              {props.title}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {props.subtitle}
            </Typography>
          </Box>
        </FadeInAnimator>
      </Box>

      {!props.isMobile &&
        <Box sx={{
          flexGrow: 1,
          flexBasis: 0, // force equal sizing to sibling divs
        }}/>
      }
    </Box>
  );
}
