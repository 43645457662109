import * as React from 'react';
import { useMemo } from 'react';
import { Header } from "./components/Header";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PaulContext } from "./domain/PaulContext";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Navigate, Route, Routes } from "react-router-dom";
import { Certificates } from "./routes/Certificates";
import { About } from "./routes/About";
import "./App.css";
import { Videos } from "./routes/Videos";

export default function App() {
  const theme = useTheme();

  // Screen less than 900px
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navHeight = useMemo(() => isMobile ? 48 * 2 : 60, [ isMobile ]);

  console.log(`navHeight ${navHeight}`)

  return (
      <PaulContext.Provider value={{
        isMobile: isMobile,
        navHeight: navHeight,
      }}>

        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box className="fixed">
            <Header/>
          </Box>

          <Box sx={{flexGrow: 1, marginTop: `${navHeight}px`}}>
            <Routes>
              <Route path="/about" element={<About/>}/>
              <Route path="/certificates" element={<Certificates/>}/>
              <Route path="/videos" element={<Videos/>}/>
              <Route
                path="*"
                element={<Navigate to="/about" replace />}
              />
            </Routes>
          </Box>

        </Box>
      </PaulContext.Provider>
  );
}
