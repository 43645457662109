import React from 'react';

interface IContext {
  isMobile: boolean;
  navHeight: number;
}

export const PaulContext = React.createContext<IContext|null>(null);

export const usePaulContext = () => {
  const context = React.useContext(PaulContext);

  if (!context) {
    throw new Error("useMyContext must be used within a <Parent />");
  }

  return context;
};
