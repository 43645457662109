import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { usePaulContext } from "../domain/PaulContext";
import ferrisWheel from "../images/ferrisWheel.jpeg";
import mountain from "../images/mountain.jpeg";
import ferrari from "../images/ferrari.jpeg";
import banawe from "../images/banawe.jpeg";
import "./About.css"
import { DualBox } from "../components/DualBox";
import { HelloThere } from "../components/HelloThere";

export const About: React.FC = () => {

  const { isMobile, navHeight } = usePaulContext();
  const [ localWindowHeight, setLocalWindowHeight ] = useState<number>(window.innerHeight - navHeight);

  const resizeHandler = () => {
    setLocalWindowHeight(window.innerHeight - navHeight);
  }

  useEffect(() => {
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    },
    []
  )

  const defaultStyle = {
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundPositionY: isMobile ? 0 : navHeight,  // remove the offset on mobile coz we are not using parallax
    backgroundAttachment: isMobile ? "inherit" : "fixed",  // disable parallax on mobile
    backgroundSize: "cover",
    zIndex: -1,
  };

  return (
    <Box sx={{ height: "100%" }}>
      <HelloThere defaultStyle={defaultStyle} height={localWindowHeight}/>

      <Box sx={{ height: localWindowHeight, backgroundImage: `url(${banawe})`, ...defaultStyle }}>
        <DualBox bgColor={"#129b34"} title="My background" isMobile={isMobile}
                 subtitle="I started my career in 2008. I continuously learn new technologies to expand my skillset. I enjoy collaborating with people to deliver the best outcome. In my current job, my primary role is front-end development whilst also helping out as a solution architect and occasionally doing back-end work."
        />
      </Box>

      <Box sx={{ height: localWindowHeight, backgroundImage: `url(${ferrisWheel})`, ...defaultStyle }}>
        <DualBox bgColor={"#2081c1"} counter="01" title="Front-end skills" isMobile={isMobile}
                 subtitle="Typescript, React, Redux, Hooks, Vue, Vuex, SCSS"/>
      </Box>

      <Box sx={{ height: localWindowHeight, backgroundImage: `url(${mountain})`, ...defaultStyle }}>
        <DualBox bgColor={"#AC8EFF"} counter="02" title="Back-end skills" isMobile={isMobile}
                 subtitle="Java/Springboot, Python/Flask, Node, Mongo, SQL"/>
      </Box>
      <Box sx={{ height: localWindowHeight, backgroundImage: `url(${ferrari})`, ...defaultStyle }}>
        <DualBox bgColor={"#AF0E0E"} counter="03" title="Cloud skills" isMobile={isMobile}
                 subtitle="AWS, Docker, TeamCity"/>
      </Box>
    </Box>
  )
}
