export const myCerts = {
  baseUrl: "https://paulpatena.com/public_images/certs",
  certs: [
    "AWSArchitect960x742.jpg",
    "AWSDeveloper960x742.jpg",
    "scrum.jpg",
    "CppTestResult2018-720x960.jpg",
    "Python3TestResult2020-720x960.jpg",
    "plusight-creds.png",
    "lbs.jpg",
    "solutions-architect-course-min.jpg",
    "nodejs.jpg",
    "react-cert-min.jpg",
    "py-web.jpg",
    "django.jpg",
    "udemy-aws-solarch-compressed.jpg",
    "web-dev2.jpg",
    "kotlin.jpg",
    "interactive-python.jpg",
    "py-fund.jpg",
    "cs.jpg",
    "estimation.jpg",
    "embed.jpg",
    "swift.jpg",
    "css.jpg",
    "html.jpg",
    "jquery.jpg",
    "js.jpg",
    "mysql.jpg",
    "php.jpg",
  ]
}

export const videoLinks = [
  { src: 'https://www.youtube.com/embed/UHfnlHlAXYU',
    text: 'IoT Smart Button having remote tracking and SMS notifications.'},
  { src: 'https://www.youtube.com/embed/sHBQciVpgmk',
    text: 'Opening my garage door using web interface connected to my microcontroller, switch and key fob.'},
  { src: 'https://www.youtube.com/embed/OsXU2uyPV80',
    text: 'Demonstrating Internet of Things by controlling LED lights using web interface.'},
  { src: 'https://www.youtube.com/embed/HG8vboyqRLM',
    text: 'Demo of a relay switch controlling a 240V supply to a lamp.'},
  { src: 'https://www.youtube.com/embed/4b6prDvchfw',
    text: 'Snake Game implemented with 4x4 switch, bare-metal arduino uno, neopixel matrix.'},
  { src: 'https://www.youtube.com/embed/Tx8GTx0hlSc',
    text: 'Snake Game implemented with gyroscrope sensors, bare-metal arduino uno, and neopixel matrix.'}
]
