import React, { useMemo, useRef } from "react";
import { useRect } from "../hooks/useRect";
import { Box } from "@mui/material";
import "./FadeInAnimator.css";

interface IProps {
  children: React.ReactNode;
  effect?: 'fade-right' | 'fade-left' | 'fade-bottom';
  visibleAreaPercent?: number;
}

export const FadeInAnimator: React.FC<IProps> = (props) => {

  const {visibleAreaPercent = 0.9, effect = 'fade-right'} = props;

  const contentRef = useRef(null);
  const contentRect = useRect(contentRef);

  const isActive = useMemo(()=>{
      return contentRect.top < window.innerHeight * visibleAreaPercent;
    },
    [contentRect]);


  return (
    <Box ref={contentRef} className={'reveal' + (isActive ? ` active ${effect}` : '')}>
      {props.children}
    </Box>
  );
}
