import React from "react";
import { spaceGray2, spaceGray3 } from "../domain/MyTheme";
import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

export const NavIcon: React.FC<{
  position: 'left' | 'right';
  behavior: 'navLeft' | 'navRight' | 'close';
  clickHandler: () => void;
  isVertCentered?: boolean;
  size?: number;
  color?: string;
}> = (props) => {
  const {
    position,
    behavior,
    clickHandler,
    isVertCentered = false,
    size = 48,
    color = spaceGray2,
  } = props;
  return (
    <Box
      sx={{
        position: "fixed",
        top: isVertCentered ? "45%" : "5%",
        right: position === 'right' ? "5%" : undefined,
        left: position === 'left' ? "5%" : undefined,
        cursor: "pointer",
        ":hover": {
          borderRadius: 1,
          backgroundColor: spaceGray3,
          opacity: '50%',
        },
      }}
      onClick={clickHandler}
    >
      {behavior === 'navLeft' && <ChevronLeftIcon sx={{ fontSize: size, color: color }}/>}
      {behavior === 'navRight' && <ChevronRightIcon sx={{ fontSize: size, color: color }}/>}
      {behavior === 'close' && <CloseIcon sx={{ fontSize: size, color: color }}/>}
    </Box>
  );
}