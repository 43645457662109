import { Box } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./MyNavLink.css";

interface IProps {
  children?: JSX.Element | string;
  to: string;
}

export const MyNavLink: React.FC<IProps> = (props) => {

  return (
      <NavLink to={props.to}
               style={{ textDecoration: 'none' }}
               className={({ isActive }) => {
                 return isActive ? 'activeLink' : undefined;
               }}
      >
        <Box sx={{
          px: 1,
          py: 1,
          cursor: "pointer",
          color: "white",
          // borderBottom: isActiveLink ? `1px solid ${secondary}` : "",
        }}
        >
          {props.children}
        </Box>
      </NavLink>
  );
}
