import { MyNavLink } from "./MyNavLink";
import React from "react";
import { Box } from "@mui/material";

export const NavLinks = () => {
  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center' }}
      >
        <MyNavLink to="/about">About</MyNavLink>
        <MyNavLink to="/certificates">Certificates</MyNavLink>
        <MyNavLink to="/videos">Videos</MyNavLink>
      </Box>
    </>
  );
}
