import { ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export const primary = '#003060'; //'#05445E ';  // Navy blue
export const secondary = '#055C9D';  // Blue grotto
export const accent = '#0E86D4';  // Blue green
export const background = '#D3E5FF'; //'#68BBE3';
export const spaceGray2 = '#a7adba'; // higher number is darker
export const spaceGray3 = '#65737e';
// export const background = '#D4F1F4';  // Baby blue

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    background: {
      default: background,
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Source Sans Pro'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    }
  }
};

export const paulMuiTheme = createTheme(
  themeOptions
);
