// noinspection DuplicatedCode

import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { background, primary } from "../domain/MyTheme";
import "./Certificates.css";
import { videoLinks } from "../domain/globalConstants";
import Typography from "@mui/material/Typography";

const PADDING = 4;
const ASPECT_RATIO = 0.5625; // 16x9, height is 9/16 of width

export const Videos: React.FC = () => {
  const [ frameHeight, setFrameHeight ] = useState(100);

  const theme = useTheme();
  const largeAndUp = useMediaQuery(theme.breakpoints.up("lg"));
  const smallAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const framesPerRow: number = useMemo(() => {
    if (largeAndUp) {
      return 3;
    } else if (smallAndUp) {
      return 2;
    }
    return 1;
  }, [ largeAndUp, smallAndUp ])

  const handleResize = () => {
    setFrameHeight((window.innerWidth - (PADDING * 16 * (framesPerRow + 1))) / framesPerRow * ASPECT_RATIO);
  }

  useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize); // Browser support, remove freely
      return () => {
        window.removeEventListener("resize", handleResize)
      };
    },
    [ framesPerRow ])

  return (
    <Box sx={{ p: PADDING, backgroundColor: background }}>
      <Grid container spacing={PADDING} alignItems={'stretch'}>
        {videoLinks.map((videoLink) => (
          <Grid item xs={12} sm={6} lg={4} key={videoLink.src}>
            <Paper className={'videoThumb'} sx={{backgroundColor: primary, height: '100%'}}>
              <Box sx={{ height: frameHeight, pt: 1 }}>
                <iframe src={videoLink.src}
                        style={{
                          border: 'none',
                          width: '100%',
                          height: '100%'
                        }}
                        allowFullScreen
                />
              </Box>
              <Typography variant={'body1'} sx={{ px: 2, py: 1 }}>
                {videoLink.text}
              </Typography>
            </Paper>
          </Grid>))
        }
      </Grid>
    </Box>
  );
}
