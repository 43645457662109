import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { background } from "../domain/MyTheme";
import "./Certificates.css";
import { NavIcon } from "../components/NavIcon";
import { myCerts } from "../domain/globalConstants";

const PADDING = 4;
const ASPECT_RATIO = 0.75; // 4x3, height is 3/4 of width

export const Certificates: React.FC = () => {
  const [ frameHeight, setFrameHeight ] = useState(100);
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [ currentCertIdx, setCurrentCertIdx ] = useState<number>(0);

  const theme = useTheme();
  const largeAndUp = useMediaQuery(theme.breakpoints.up("lg"));
  const smallAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const framesPerRow: number = useMemo(() => {
    if (largeAndUp) {
      return 3;
    } else if (smallAndUp) {
      return 2;
    }
    return 1;
  }, [ largeAndUp, smallAndUp ])

  const modalWidth = useMemo(() => window.innerWidth * (framesPerRow !== 1 ? 0.85 : 1), [ framesPerRow ]);
  const modalHeight = useMemo(() => window.innerHeight * (framesPerRow !== 1 ? 0.85 : 1), [ framesPerRow ]);

  const handleResize = () => {
    setFrameHeight((window.innerWidth - (PADDING * 16 * (framesPerRow + 1))) / framesPerRow * ASPECT_RATIO);
  }

  useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize); // Browser support, remove freely
      return () => {
        window.removeEventListener("resize", handleResize)
      };
    },
    [ framesPerRow ])

  const navigateBack = () => setCurrentCertIdx((idx) => idx > 0 ? idx - 1 : myCerts.certs.length - 1);
  const navigateForward = () => setCurrentCertIdx((idx) => idx >= myCerts.certs.length - 1 ? 0 : idx + 1);


  useEffect(() => {
    window.addEventListener("keydown", keyboardHandler)
    return () => window.removeEventListener("keydown", keyboardHandler)
  }, [])

  const keyboardHandler = (event: any) => {
    console.log('handler called')
    if (event.key === 'ArrowLeft') {
      navigateBack();
    }
    if (event.key === 'ArrowRight') {
      navigateForward();
    }
  };

  return (
    <Box sx={{ p: PADDING, backgroundColor: background }}>
      <Grid container spacing={PADDING}>
        {myCerts.certs.map((certName, idx) => (
          <Grid item xs={12} sm={6} lg={4} key={certName}>
            <Box sx={{ height: frameHeight }}>
              <img src={`${myCerts.baseUrl}/${certName}`} alt={certName}
                   className={'certThumb'}
                   onClick={() => {
                     setCurrentCertIdx(idx);
                     setIsModalOpen(true);
                   }}
              />
            </Box>

          </Grid>))
        }
      </Grid>

      {isModalOpen &&
        <div className={"modal"}>
          <Box sx={{
            width: modalWidth,
            height: modalHeight,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            // border: `1px solid green`,
          }}
               onClick={() => {
                 setIsModalOpen(false)
               }}
          >
            <img src={`${myCerts.baseUrl}/${myCerts.certs[currentCertIdx]}`} alt={myCerts.certs[currentCertIdx]}
                 id="spotlight-image"
                 className={'fade-in-image'}
                 style={{
                   margin: "auto",
                   height: "100%",
                   objectFit: 'contain',
                   overflow: 'hidden',
                   borderRadius: 16,
                 }}
            />
          </Box>

          <NavIcon behavior={'close'}
                   position={'right'}
                   clickHandler={() => setIsModalOpen(false)}
          />
          <NavIcon isVertCentered
                   behavior={'navLeft'}
                   position={'left'}
                   clickHandler={navigateBack}
          />
          <NavIcon isVertCentered
                   behavior={'navRight'}
                   position={'right'}
                   clickHandler={navigateForward}
          />
        </div>
      }

    </Box>
  );
}
